.call-now {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #151b54;
    background-color: #ffffff;
    border: unset;
  }
  
  .whatsapp-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    border: unset;
    background-color: unset;
    color: #ffffff;
  }
  .social-media-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    background-color: white;
    display: flex;
  }
  
  .half-div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  