h4 {
  font-size: 2.5rem !important;
  color: #2e3234 !important;
}
h5 {
  font-size: 1.5rem !important;
}
h5 span {
  color: #f2d03a !important;
}
p {
  font-size: 1.1rem !important;
  color: rgb(74, 74, 74);
}
#root {
  overflow-x: hidden !important;
}

/* Header */

.offcanvas {
  width: 250px !important;
}

.header-routes-canvas a {
  list-style: none;
  padding: 20px 10px;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
}

.header-routes-canvas a {
  text-decoration: none;
}
.header-routes a {
  text-decoration: none;
  color: white;
}
.header-routes a:hover {
  color: #f2d03a;
  transition: all ease-in-out 0.5s;
}
.header-routes a.active {
  color: #f2d03a;
}

.header-routes {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}
.header-routes a {
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  height: 70px;
  display: flex;
  padding: 0 30px;
  background-color: #2e3234;
  color: white !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  top: 0;
  position: fixed;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

/* footer */

.high-cliff {
  font-size: 8rem;
  color: #2e3234;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 6.5rem;
  text-align: center;
  font-weight: 700;
}
.footer {
  background: #2e3234;
  color: #ffffff;
}

.footer p {
  color: #ffffff !important;
  font-size: 1rem !important;
}
.footer svg {
  color: #f2d03a;
  font-size: 1.5rem;
}
.footer h6 {
  color: #f2d03a !important;
  font-size: 1.2rem !important;
}
.footer input {
  background: #ffffff;
  border: none;
  height: 50px;
  color: #2e3234 !important;
}
.footer input:focus {
  background: #ffffff !important;
  border: none;
  box-shadow: none !important;
  /* border: 2px solid #F2D03A !important; */
  height: 50px;
}
.footer .btn-yellow {
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 1.2rem;
  background: #f2d03a;
  margin-top: 20px;
}
.footer .form-control {
  border-radius: 2px !important;
}

.modal-title {
  display: flex;
  justify-content: center;
  margin: auto;
}
button.btn-close {
  position: absolute;
  right: 30px;
}
.modal-form input {
  height: 50px;
  border: 2px solid #2e3234;
  border-radius: 5px;
  color: #2e3234;
}
.phone-input input {
  height: 50px !important;
  border: 2px solid #2e3234 !important;
  border-radius: 5px !important;
  color: #2e3234 !important;
  width: 100% !important;
}
.modal-form select {
  height: 50px;
  border: 2px solid #2e3234;
  border-radius: 5px;
  color: #2e3234;
}
.form-control:focus {
  box-shadow: none !important;
}

.flag-dropdown {
  border: 2px solid #2e3234 !important;
}
.confirm-page-container {
  background: #fff8dc;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Section 1  */
.home-sec-1 {
  margin-top: 70px;
}

.home-sec-1 .slick-next {
  right: 30px !important;
  /* top: calc(100vh - 110px) !important; */
  border: 2px solid #ebf9f7ba !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}
.home-sec-1 .slick-prev {
  left: 30px !important;
  /* top: calc(100vh - 110px) !important; */
  border: 2px solid #ebf9f7ba !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}

.home-sec-1 {
  height: calc(100vh - 70px);
}
.home-sec-1 .home-img img {
  height: calc(100vh - 70px);
  width: 100%;
}

.footer .text-yellow {
  color: #f2d03a !important;
}

.section-12 h6.text-yellow {
  color: #f2d03a !important;
}
.logo {
  height: 50px;
}
p span {
  color: #f2d03a !important;
  font-weight: 550;
}

.react-tel-input .country-list .country-name {
  color: #2e3234;
}

/* Section 11 */

.accordion-item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  /* border-radius: 8px !important; */
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #2e3234 !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  background: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  padding: 5px !important;
  border-radius: 50%;
  background-size: 70% 70% !important;
  background-position: center !important;
}
/* .accordion-flush .accordion-item .accordion-button,
 .accordion-flush .accordion-item .accordion-button.collapsed {
   border-radius: 8px !important;
 } */

.accordion-flush .accordion-item .accordion-button:not(.collapsed) {
  background: #2e3234 !important;
  color: #f2d03a !important;
  font-weight: 700 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid white !important;
}
.accordion-collapse.show {
  background: #2e3234 !important;
  color: #ffffff !important;
}

/* Section 2 */

.section-2 {
  background-color: #2e3234;
}

.section-2 h5,
.section-2 p {
  color: white !important;
}

.btn-yellow {
  background: #f2d03a;
  border: none;
  padding: 8px 25px;
}

/* wave */
.ocean {
  height: 5%;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  background: #2e3234;
}

.wave {
  background: url(../../Images/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 8s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 8s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/* Section 3  */

/* .section-3 {
  background-image: url("../../Images/Sec3-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} */

.card-overview {
  height: 100px;
  width: 250px;
  z-index: 3;
  border-radius: 5px;
  background: #2e3234;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card-overview h5 {
  color: #f2d03a !important;
}
.card-overview p {
  color: white !important;
}

/* Section 4  */

.section-4 .img-text-section .text-sec {
  background-color: #2e3234;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
}
.section-4 .img-text-section .text-sec h5 {
  color: #f2d03a !important;
}
.section-4 .img-text-section .text-sec p {
  color: white !important;
}
.section-4 .img-text-section .img-sec img {
  width: 100%;
  height: 100%;
  border: 15px solid #f2d03a;
}

/* section-5 */

thead,
.thead tr {
  height: 80px !important;
  border-style: none !important;
}

.table > thead {
  color: #f2d03a !important;
  background-color: #2e3234 !important;
}
.table > thead > tr > th {
  background-color: #2e3234 !important;
  vertical-align: middle !important;
  color: #f2d03a !important;
  border-style: none !important;
  text-align: center !important;
  font-size: 1.2rem;
}
.table > tbody > tr > td {
  vertical-align: middle !important;
  border-style: none !important;
  text-align: center !important;
}
.table > thead > tr {
  height: 100px !important;
  border-radius: 10px !important;
}
.table > tbody > tr {
  height: 100px !important;
  border-radius: 10px !important;
}
.get-price-btn {
  background-color: #2e3234;
  color: #f2d03a;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  width: max-content;
  border-radius: 8px;
  margin: auto;
}

/* Section 6 */

.section-6 {
  background: #2e3234;
}
.section-6 h4 {
  color: white !important;
}
.section-6 h5 {
  color: white !important;
}
.section-6 p {
  color: white !important;
}

.box-aminity .box-in {
  min-height: 335px;
  border: 2px solid #f2d03a;
  background: #242526;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
}

.box-aminity .logo-icon {
  background: #f2d03a;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.box-aminity .logo-icon img {
  height: 30px;
  width: 30px;
}

/* Section 7 */
.section-7 {
  background: #2e3234;
}
.section-7 img {
  width: 90px;
}

.section-7 h5 {
  color: white !important;
}

.boxes-usps .usp {
  padding: 20px 30px;
  display: flex;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.boxes-usps .usp svg {
  color: #f2d03a;
  font-size: 2rem;
}
.boxes-usps .usp p {
  color: white !important;
}

.section-7 .text-yellow {
  color: #f2d03a !important;
  text-align: center;
}

/* Section 8 */

.section-8 h5 {
  color: white !important;
}

.section-8 {
  background: #242526;
}

.design .card-box-img {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.8);
  opacity: 0.7;
}
.design .card-box-img img {
  max-height: 450px;
}
.slick-center {
  opacity: 1;
}

.design .slick-center .card-box-img {
  border: 5px solid #f1ce3c;
  transform: scale(1);
  opacity: 1;
}

.design .slick-next {
  right: 180px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}
.design .slick-prev {
  left: 180px !important;
  background: #f2d03a !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}
.design .slick-dots li button:before {
  font-size: 12px !important;
  color: transparent !important;
  top: 20px !important;
  border: 3px solid #f2d03a !important;
  border-radius: 50% !important;
}

/* Section 10 */

.section-10 h5 {
  color: white !important;
}
.section-10 .card-box h5 {
  color: #242526 !important;
}

.section-10 {
  background: #2e3234;
}

.musings .card-box {
  border-radius: 10px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  min-height: 325px;
  background: white;
  transform: scale(0.8);
  opacity: 0.7;
}
.musings .card-box h5 {
  text-align: justify !important;
}
.musings .card-box svg {
  color: #f2d03a;
}

.musings .slick-center .card-box {
  transform: scale(1);
  opacity: 1;
}

.musings .slick-center .card-box-img {
  transform: scale(1);
  opacity: 1;
}

.musings .slick-next {
  right: 180px !important;
  background: #f1ce3c !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}
.musings .slick-prev {
  left: 180px !important;
  background: #f2d03a !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}
.musings .slick-dots li button:before {
  font-size: 12px !important;
  color: transparent !important;
  top: 20px !important;
  border: 3px solid #f2d03a !important;
  border-radius: 50% !important;
}

/* Section 9 */

.section-9 {
  background: #242526;
}

.section-9 h5 {
  color: white !important;
}

.section-9 .box {
  padding: 10px;
}
.section-9 div.box.map {
  min-height: 400px !important;
}

.section-9 .box-inside {
  background: #f2d03a;
  height: 250px;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-9 .box-inside:hover {
  background: #ffffff;
  transition: all ease-in-out 1s;
  transform: scale(1.05);
}

.section-9 ul {
  padding-left: 1rem !important;
}

/* Section 12 */

.section-12 {
  background-color: #242526;
  z-index: 3;
  position: relative;
}
.section-12 h5 {
  color: white !important;
}
.section-12 p {
  color: white !important;
}
.section-12 h6 {
  color: white !important;
}
.section-12 svg {
  color: #f2d03a !important;
  font-size: 50px !important;
}

/* section 13 */

.section-13 {
  background: #242526;
}

.section-13 h5 {
  color: #ffffff !important;
}
.section-13 h6 span {
  color: #f2d03a !important;
}
.section-13 .musings img {
  width: 100%;
}

.section-13 .box {
  border: 1px solid #f2d03a;
  background: #2e3234;
  border-radius: 5px;
  width: 47%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
  padding: 5px;
}
.section-13 .box p {
  color: #ffffff !important;
  text-align: center;
  font-size: 0.8rem !important;
}

.redirect-img {
  position: absolute;
  bottom: 50px;
  left: 50px;
}
.redirect-img img {
  height: 80px;
}

@media (max-width: 1330px) {
  .musings .card-box {
    min-height: 350px;
  }
}
@media (max-width: 1220px) {
  .musings .card-box {
    min-height: 400px;
  }
}
@media (max-width: 1100px) {
  .musings .card-box {
    min-height: 480px;
  }
}
@media (max-width: 1024px) {
  .musings .slick-next {
    right: 5px !important;
  }
  .musings .slick-prev {
    left: 5px !important;
  }
  .musings .card-box {
    min-height: 400px;
  }
}
@media (max-width: 884px) {
  .section-9 .box-inside {
    height: 280px;
  }
}
@media (max-width: 768px) {
  .high-cliff {
    font-size: 4rem;

    line-height: 3.2rem;
  }
  .design .slick-next {
    right: 5px !important;
  }
  .design .slick-prev {
    left: 5px !important;
  }
  .musings .slick-next {
    right: 5px !important;
  }
  .musings .slick-prev {
    left: 5px !important;
  }
  .boxes-usps .usp {
    padding: 15px 8px;
    min-width: 50%;
  }
  .musings .card-box {
    min-height: 450px;
  }
  .redirect-img {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .redirect-img img {
    height: 50px;
  }
}
@media (max-width: 628px) {
  .musings .card-box h5 {
    font-size: 1.2rem !important;
  }
  .section-7 img {
    width: 40px;
  }
}
@media (max-width: 470px) {
  .musings .card-box h5 {
    font-size: 1.1rem !important;
  }
}
@media (max-width: 410px) {
  .musings .card-box h5 {
    font-size: 1rem !important;
  }
}
